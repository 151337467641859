import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../shared/api/api.service';
import { components } from '../../../shared/api/models';
import {
  ApiResponse,
  SearchInput,
  SearchService,
} from '../../../shared/service/search.service';
import { IFilter } from './proposal.store';

@Injectable({
  providedIn: 'root',
})
export class ProposalService
  implements
    SearchService<
      components['schemas']['ProposalListRecordV2'],
      components['schemas']['ProposalSearchRecordV2']
    >
{
  #apiService = inject(ApiService);

  constructor() {
    console.log('hi there');
  }

  search(payload: SearchInput<Partial<any>>): Observable<ApiResponse<any>> {
    return this.#apiService.callApi('/core/proposal/v1/search', 'post', {
      content: {
        // 'application/json': { ...payload, filter: payload.filter || {} },
        'application/json': this.buildSearchPayload(payload),
      },
    });
  }

  private buildSearchPayload(input: SearchInput<Partial<IFilter>>) {
      return <
        SearchInput<Partial<components['schemas']['ProposalSearchRecordV2']>>
      >{
        ...input,
        filter: {
          ...input.filter,
  
          // clusterID: input.filter?.clusterID?.code,
          stateID: input.filter?.stateID?.code,
          clientId:  input.filter?.clientId?.id,
          tenderTittle: input.filter?.tenderTittle,
          tenderRefNo: input.filter?.tenderRefNo,
          status:  this.filterStatusName(input.filter?.status),
          financialYearId: input.filter?.financialYearId?.code,
          leadRecordId: input.filter?.leadRecordId
        },
      };
    }

    filterStatusName(statusArr:[]){
      console.log(statusArr);
      if(statusArr){
        return statusArr.map((status:any) => status.code);
      }else {
        return ;
      }
      
    }

    fetchProposalStatistic(payload: any) {
      // return this.#apiService.callApi('/core/lead/v1/statistics', 'get');
      return this.#apiService.callApi('/core/proposal/v1/statistics', 'post', {
        content: { 'application/json': this.buildSearchPayload(payload) }
      }
    );
    }


    // components['schemas']['SearchInputProposalSearchRecord']
  export(payload: any) {
    return this.#apiService.downloadFileRequest(
      '/core/proposal/v1/export',
      'post',
      this.buildSearchPayload(payload)
    );
  }
}
